import * as React from 'react';

import { ReactComponent as SecureLockIcon } from 'assets/icons/secure-lock.svg';

import { Container, Details, Title } from './MembershipDetails.styled';

export const MembershipDetails = ({ hasEmployer }: { hasEmployer: boolean }) => {
  if (!hasEmployer) return null;

  return (
    <Container>
      <Title>
        <span>Enter Your Access Code</span> <SecureLockIcon />
      </Title>
      <Details>
        Your benefits provider has given you an access code in order to unlock your Kindbody
        membership. Please enter it here.
      </Details>
    </Container>
  );
};
