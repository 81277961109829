import React from 'react';
import { useHistory } from 'react-router-dom';

import { usePatient } from 'hooks';
import { useRedirectIfMember } from 'kb-shared/booking/hooks/useRedirectIfMember';
import { LoadedEntities } from 'kb-shared/booking/types/types';
import { Appointment } from 'kb-shared/types';
import { Clinic, TimeSlot } from 'kb-shared/types';
import { RoutesPath } from 'utilities/pageUrl';
import { hasEmployer } from 'utilities/patient';

import { selectedProductHasEmployer } from '../../Book.utils';
import { CoachingBenefitsDisclaimer } from './components/CoachingBenefitsDisclaimer';
import { CreateAppointmentForm } from './components/CreateAppointmentForm';
import { LicensedProviderDisclaimer } from './components/LicensedProviderDisclaimer';
import { MembershipDetails } from './components/MembershipDetails';
import { PaymentDetails } from './components/PaymentDetails';
import { RescheduleAppointmentForm } from './components/RescheduleAppointmentForm';
import { Container, PaymentFormWrapper } from './Confirm.styled';

export const ConfirmStep = ({
  selectedClinic,
  selectedProduct,
  selectedTimeSlot,
  onBookAppointment,
  providerId
}: {
  selectedClinic?: Clinic;
  selectedProduct?: LoadedEntities['selectedProduct'];
  selectedTimeSlot?: TimeSlot;
  onBookAppointment: (appointment: Appointment) => void;
  providerId?: number;
}) => {
  const history = useHistory();
  const { patient } = usePatient();

  useRedirectIfMember({ product: selectedProduct ?? null });

  if (selectedProduct?.type === 'enterprise_membership') {
    history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);

    return null;
  }

  const query = new URLSearchParams(history.location.search);
  const isOldMembership = query.get('membership_id');

  if (isOldMembership != null) {
    query.delete('membership_id');
    query.append('enterprise_membership', 'true');
    history.replace(`${history.location.pathname}?${query.toString()}`);

    return null;
  }

  // wait until the product is loaded
  if (!selectedProduct) return null;

  const hasMembershipEmployer = hasEmployer(patient);
  const patientHasEmployer = selectedProductHasEmployer(selectedProduct);
  const timeSlot = selectedTimeSlot ?? null;
  const clinic = selectedClinic ?? null;

  return (
    <Container>
      <LicensedProviderDisclaimer product={selectedProduct} />
      <CoachingBenefitsDisclaimer product={selectedProduct} hasEmployer={hasMembershipEmployer} />
      <MembershipDetails hasEmployer={patientHasEmployer} />
      <div>
        <PaymentDetails product={selectedProduct} hasEmployer={patientHasEmployer} />
        <PaymentFormWrapper>
          <RescheduleAppointmentForm
            product={selectedProduct}
            timeSlot={timeSlot}
            clinic={clinic}
          />
          <CreateAppointmentForm
            providerId={providerId}
            product={selectedProduct}
            timeSlot={timeSlot}
            clinic={clinic}
            hasEmployer={patientHasEmployer}
            onBookAppointment={onBookAppointment}
          />
        </PaymentFormWrapper>
      </div>
    </Container>
  );
};
