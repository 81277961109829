import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { usePromo } from 'hooks/usePromo';
import {
  Appointment,
  AppointmentProduct,
  AppointmentType,
  Clinic,
  PatientMembership,
  TimeSlot,
  ValidPromo
} from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { PATIENT_MEMBERSHIP } from './AppointmentSummaryDetails.graphql';
import { ConfirmAppointmentDetails } from './ConfirmationAppointmentDetails';

interface Props {
  clinic: Clinic | null;
  appointment: AppointmentType | Appointment | null;
  product: AppointmentProduct<AppointmentType>;
  timeSlot: TimeSlot | null;
  isReschedule?: boolean;
  loading?: boolean;
  disabled?: boolean;
  paymentTermsChecked: boolean;
  onPaymentTermsChecked: (checked: boolean) => void;
  onValidPromoChange: (validPromo: ValidPromo | null) => void;
  validPromo: ValidPromo | null;
}

export const AppointmentSummaryDetails = (props: Props) => {
  const {
    appointment,
    timeSlot,
    clinic,
    paymentTermsChecked,
    onPaymentTermsChecked,
    onValidPromoChange,
    isReschedule = false,
    loading = false,
    disabled = false,
    product,
    validPromo
  } = props;

  const [membershipPromoCode, setMembershipPromoCode] = useState<string | null>(null);
  const { validatePromo } = usePromo(product);
  const [hasEmployerMembership, setHasEmployerMembership] = useState<boolean>(false);

  useQuery<PatientMembership>(PATIENT_MEMBERSHIP, {
    skip: !!membershipPromoCode,
    onCompleted: data => {
      if (membershipPromoCode) return;
      const firstRule = data?.patient?.patientMembership?.membershipRules?.[0];
      if (!firstRule) return;

      const discountCode = firstRule.code;

      setHasEmployerMembership(data?.patient.patientMembership?.employer || false);

      setMembershipPromoCode(discountCode);
      validatePromo(discountCode)
        .then(({ validPromo }) => {
          onValidPromoChange(validPromo || null);
        })
        .catch(error =>
          BugTracker.notify(error, 'Validate Promo Code during Payment Confirmation')
        );
    },
    onError: error => BugTracker.notify(error, 'AppointmentSummaryDetails')
  });

  return (
    <ConfirmAppointmentDetails
      loading={loading}
      disabled={disabled}
      appointment={appointment}
      timeSlot={timeSlot}
      clinic={clinic}
      termsAccepted={paymentTermsChecked}
      isReschedule={isReschedule}
      discountPrice={validPromo?.totalCents}
      hasEmployerMembership={hasEmployerMembership}
      onTermsAcceptToggled={() => onPaymentTermsChecked(!paymentTermsChecked)}
    />
  );
};
