import { Heading, Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Title = styled(Heading).attrs({
  tag: 'h2',
  styledAs: 'h5',
  noMargin: true,
  flexDirection: 'row'
})`
  gap: 8px;
`;

export const Details = styled(Text).attrs({ size: 'md' })``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
