import * as React from 'react';
import { useState } from 'react';

import { CreditCard } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import { useFeatureFlags } from 'hooks';
import { isCoachingAppointment, isGYNAppointment } from 'kb-shared/utilities/appointment.helper';
import CancellationPolicyModal from 'screens/Book/components/CancellationPolicyModal';

import {
  Wrapper,
  Title,
  Details,
  CancellationPolicyLink,
  CancellingInfo,
  CancellingInfoTitle
} from './PaymentDetails.styled';

export type ProductType =
  | 'REGULAR_APPOINTMENT'
  | 'REQUIRES_IMMEDIATE_PAYMENT'
  | 'GYN_APPOINTMENT'
  | 'RESCHEDULE_APPOINTMENT';

type Props = {
  product: any;
  hasEmployer: boolean;
};

export const PaymentDetails = ({ product, hasEmployer }: Props) => {
  const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
  const { ppCreditCardOptional } = useFeatureFlags();

  const isCoaching = isCoachingAppointment(product?.data);
  const isCoverByHolisticHealth = isCoaching && product?.data?.shouldChargeAtBooking === false;
  const isFreeOfCharge =
    !product || !product.data || product.data.rateCents === 0 || isCoverByHolisticHealth;

  if (isFreeOfCharge || hasEmployer) return null;

  let productType: ProductType = 'REGULAR_APPOINTMENT';

  if (product.type === 'appointment') {
    const categoryId = product?.data.category.id;
    const requiresImmediatePayment = isCoaching;
    const isGYN = isGYNAppointment(parseInt(categoryId));

    if (requiresImmediatePayment) {
      productType = 'REQUIRES_IMMEDIATE_PAYMENT';
    } else if (isGYN) {
      productType = 'GYN_APPOINTMENT';
    }
  } else if (product.type === 'reschedule_appointment') {
    productType = 'RESCHEDULE_APPOINTMENT';
  }

  return (
    <Wrapper>
      <Title>
        <CreditCard />{' '}
        {productType === 'RESCHEDULE_APPOINTMENT'
          ? 'Confirm your new appointment time'
          : 'Payment details'}
      </Title>
      <Details>
        <Text tag="p" size="md">
          {['GYN_APPOINTMENT', 'REGULAR_APPOINTMENT'].includes(productType) && (
            <>
              {!ppCreditCardOptional &&
                "We'll need a credit card to hold this appointment for you. "}

              <Text fontStyle="semibold">
                You will not be charged until the time of your appointment, and we will bill your
                insurance if you have it, subject to your co-pay and/or co-insurance.{' '}
              </Text>
            </>
          )}
          If you are unable to make your appointment, be sure to cancel within the designated
          timeframe as stipulated in our{' '}
          <CancellationPolicyLink
            onClick={() => setShowCancellationPolicy(true)}
            text="cancellation policy"
          />
          .
        </Text>

        <CancellingInfo>
          <CancellingInfoTitle>Need to cancel?</CancellingInfoTitle>

          <p>
            Please alert Kindbody to cancel or reschedule your appointment at least 24 hours prior
            to the scheduled time.
          </p>
        </CancellingInfo>

        <CancellationPolicyModal
          show={showCancellationPolicy}
          onClickClose={() => setShowCancellationPolicy(false)}
        />
      </Details>
    </Wrapper>
  );
};
