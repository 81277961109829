import React from 'react';

import Button from 'components/Buttons/NewButton';
import { Text } from 'components/v2/Typography';
import { isAppointmentType, utils } from 'kb-shared';
import theme from 'kb-shared/theme';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';

import AppointmentDetailBlock from './AppointmentDetailBlock';
import {
  Container,
  Content,
  Header,
  InsuranceContainer,
  Price,
  PriceContainer,
  SmallPrint
} from './ConfirmationAppointmentDetails.styled';
import { Props } from './ConfirmationAppointmentDetails.types';
import { TermsDetails } from './TermsDetails';

const { isGYNAppointment } = utils;

export const ConfirmAppointmentDetails: React.FC<Props> = props => {
  const {
    appointment,
    clinic,
    loading,
    disabled,
    onTermsAcceptToggled,
    termsAccepted,
    timeSlot,
    isReschedule,
    discountPrice,
    hasEmployerMembership
  } = props;

  const renderPrice = () => {
    if (isReschedule) return null;
    if (!isAppointmentType(appointment)) return null;

    const appointmentCategoryId = parseInt(appointment.category.id);
    if (isGYNAppointment(appointmentCategoryId)) return null;

    const isAppointmentFree = appointment.rateCents === 0;
    if (discountPrice === 0 || isAppointmentFree) return <Price>Free of charge</Price>;

    const isCoaching = isCoachingAppointment(appointment);
    const shouldChargeAtBookingForHolisticHealth = appointment.shouldChargeAtBooking;

    // If "Coaching" = 0, "Holistic Health Covered" can be either 0 or 1.
    // If "Coaching" = 1 and "Requires Immediate Payment" = TRUE, then "Holistic Health Covered" = 0.
    // If "Coaching" = 1 and "Requires Immediate Payment" = FALSE, then "Holistic Health Covered" = 1.
    if (isCoaching && !shouldChargeAtBookingForHolisticHealth) return null;

    if (!isCoaching) return <NoImmediatePayment />;

    const price = (discountPrice || appointment.rateCents) / 100;
    const showCreditCardHSAOrFSAInfo = isCoaching && !hasEmployerMembership;

    return (
      <PriceContainer>
        <Price>{`$${price} due ` + (isCoaching ? 'today' : 'at appointment')}</Price>
        {showCreditCardHSAOrFSAInfo && (
          <Text color={theme.colors.white} size="sm">
            We accept credit card, HSA/FSA. Have an employer benefit with wellness coverage? Call us
            today to schedule.
          </Text>
        )}
      </PriceContainer>
    );
  };

  const buttonText = isReschedule ? 'CONFIRM & RESCHEDULE' : 'CONFIRM & BOOK';

  return (
    <Container>
      <Content>
        {isAppointmentType(appointment) && (
          <AppointmentDetailBlock
            appointmentType={appointment}
            timeSlot={timeSlot}
            clinic={clinic}
          />
        )}
        {renderPrice()}
        <TermsDetails
          selected={termsAccepted}
          onCheckBoxSelected={onTermsAcceptToggled}
          showSessionDisclaimerCharge={
            //@ts-ignore
            isCoachingAppointment(appointment) &&
            //@ts-ignore
            !appointment?.shouldChargeAtBooking &&
            //@ts-ignore
            appointment.rateCents !== 0
            // @ts-check
          }
        />
        <Button
          loading={loading}
          disabled={disabled || !termsAccepted || loading}
          type="submit"
          text={buttonText}
        />
      </Content>
    </Container>
  );
};

const NoImmediatePayment = () => (
  <InsuranceContainer>
    <Header>Payment Due at Appointment</Header>
    <SmallPrint>
      *Subject to insurance coverage. If not covered, you will be responsible for the total + lab
      work.
    </SmallPrint>
  </InsuranceContainer>
);
